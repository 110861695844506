export const SET_LOADING = "SET_LOADING";
export const RESET_LOADING = "RESET_LOADING";

const FUNCTION_FLAG = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  READ: "READ",
};

const PRODUCT_FETCH_LIST_TYPE = {
  EXHIBITION: "EXHIBITION",
  NEW_ARRIVAL: "NEW_ARRIVAL",
  BEST_SELLER: "BEST_SELLER",
  RESERVATION: "RESERVATION",
  RECOMMEND: "RECOMMEND",

  AI_RECOMMEND: "AI_RECOMMEND",
};

export const DELIVERY_FREE_PRICE: number = 50000;

export const DELIVERY_FEE: number = 3000;
export const S3_SERVER_BASE_URL =
  "https://twinkleimg.s3.ap-northeast-2.amazonaws.com/";

export const DEFAULT_PAGE_SIZE = 10;

const PRODUCT_STATUS = [
  {
    value: 1,
    label: "예약",
  },
  {
    value: 2,
    label: "재고",
  },
];

const POINT_RATE = 0.03;
const PRODUCT_GLOBAL_PRICE_RATES_DEFAULT = 10;

const PRODUCT_STATUS_DEFAULT = 1;

const PRODUCT_TYPE_DEFAULT = 1;

const PRODUCT_GLOBAL_PRICE_UNIT_DEFAULT = 1;

const PRODUCT_TYPE = [
  {
    value: 1,
    label: "일반",
  },
  {
    value: 2,
    label: "가챠-구성품",
  },
  {
    value: 3,
    label: "가챠-메인",
  },
  {
    value: 4,
    label: "쿠지",
  },
  {
    value: 5,
    label: "아소토 가챠",
  },
];
const PRODUCT_GLOBAL_PRICE_UNIT = [
  {
    value: 1,
    label: "엔화",
  },
  {
    value: 2,
    label: "위안화",
  },
  {
    value: 3,
    label: "달러",
  },
  {
    value: 4,
    label: "원화",
  },
];

const PRODUCT_VISIBLITY = [
  {
    value: 1,
    label: "진열",
  },
  {
    value: 2,
    label: "진열안함",
  },
];

const PRODUCT_SALE_STATUS = [
  {
    value: 1,
    label: "판매중",
  },
  {
    value: 2,
    label: "판매중지",
  },
];

const PRODUCT_RANDOM_TYPE = [
  {
    value: 1,
    label: "무중복 랜덤 상품",
  },
  {
    value: 2,
    label: "중복 랜덤 상품",
  },

  {
    value: 3,
    label: "확정 상품",
  },
];
const PRODUCT_IS_TAX_FREE = [
  {
    value: 0,
    label: "과세",
  },
  {
    value: 1,
    label: "면세",
  },
];
const PRODUCT_OPTION_TYPE = [
  {
    value: 1,
    label: "옵션 사용 안 함",
  },
  {
    value: 2,
    label: "옵션 사용",
  },
];

const COUPON_ACTIVE = [
  {
    value: 0,
    label: "비활성",
  },
  {
    value: 1,
    label: "활성화",
  },
];

const COUPON_RANGE = [
  {
    value: 0,
    label: "전체",
  },
  {
    value: 1,
    label: "애니메",
  },
  {
    value: 2,
    label: "태그",
  },
  {
    value: 3,
    label: "카테고리",
  },
];

const BENEFIT_TYPE = [
  {
    value: 1,
    label: "쿠폰",
  },
  {
    value: 2,
    label: "포인트",
  },
];

const BENEFIT_ACTION_COUPON = [
  {
    value: 1,
    label: "발행",
  },
  {
    value: 2,
    label: "사용",
  },
  {
    value: 3,
    label: "만료",
  },
  {
    value: 4,
    label: "삭제",
  },
];

const BENEFIT_ACTION_POINT = [
  {
    value: 1,
    label: "적립",
  },
  {
    value: 2,
    label: "사용",
  },
  {
    value: 3,
    label: "만료",
  },
  {
    value: 4,
    label: "삭제",
  },
];

export default {
  POINT_RATE,
  COUPON_ACTIVE,
  COUPON_RANGE,

  BENEFIT_TYPE,
  BENEFIT_ACTION_COUPON,
  BENEFIT_ACTION_POINT,

  PRODUCT_FETCH_LIST_TYPE,
  DELIVERY_FREE_PRICE,
  DELIVERY_FEE,
  PRODUCT_TYPE,
  PRODUCT_STATUS,
  PRODUCT_GLOBAL_PRICE_UNIT,
  PRODUCT_STATUS_DEFAULT,
  PRODUCT_TYPE_DEFAULT,
  PRODUCT_GLOBAL_PRICE_UNIT_DEFAULT,
  PRODUCT_GLOBAL_PRICE_RATES_DEFAULT,

  PRODUCT_SALE_STATUS,
  PRODUCT_VISIBLITY,

  PRODUCT_RANDOM_TYPE,
  PRODUCT_OPTION_TYPE,
  PRODUCT_IS_TAX_FREE,
  FUNCTION_FLAG,
  S3_SERVER_BASE_URL,
};
